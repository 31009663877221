

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

:root {
  --primary: #3393C7;
  --secondary: #29769F ;
  --tertiary: #7ebddf;
  --text: #f0f0f0e7;
  --maxtemp: #05324a;
  --mintemp: #053c5a;

  /* Colours for day by day paper background */
  --clear: #7ebddf;
  --clear-darker: #2f9ad4;
  --clear-light: #99c6de;
  --clouds: #a3b8c3; 
  --rain: #838d93;
  --snow: #b0b0b0;

  --altcloud: #757575;
  --altmoon: #757575;


  --1: #747F85;
  --2: #959DA2;
  --3: #B6BCBF;
  --4: #dbdbdb;

  --night1: #10032E;
  --night2: #14033A;

}


.search-input::placeholder {
  font-weight: bold;
  font-size: 20px; /* Adjust font size as needed */
  color: var(--secondary); /* Adjust placeholder color as needed */
}

.search-input-mobile::placeholder {
  font-weight: bold;
  font-size: 16px; /* Adjust font size as needed */
  color: var(--secondary);
}

.search-input-desktop::placeholder {
  font-weight: bold;
  font-size: 20px; /* Adjust font size as needed */
  color: var(--secondary);
}

.search-input{
  font-weight: bold;
  font-size: 16px; /* Adjust font size as needed */
  color: var(--secondary); /* Adjust placeholder color as needed */
}


/* Default styles for desktop */
.weather-grid {

  gap: 16px;
}

/* Adjustments for mobile screens */
@media only screen and (max-width: 600px) {
  .weather-grid {
    grid-template-columns: 1fr;
  }
}


.weather-card {
  transition: transform 0.3s ease;
}

/* .weather-card:hover {
  /* transform: scale(1.02); */
  
  .suggestion-item {
    cursor: pointer;
    padding: '10px';
    background-color: '#f0f0f0';
    border-bottom: '1px solid #ddd';
    border-radius: '10px';
    margin-bottom: '8px';
    transition: background-color 0.3s ease; /* Smooth transition for background color */
  }
  
  .suggestion-item:hover {
    background-color: var(--clouds);
     /* Darker shade on hover */
  }
  